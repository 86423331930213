import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './BannerCoolebra.style';
import {
  BANNER_COOLEBRA_DESKTOP,
  BANNER_COOLEBRA_MOBILE,
} from 'images/GoogleCloud';

interface propsContact {
  noForm?: boolean;
}

const BannerCoolebra = ({ noForm }: propsContact) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 425px)');

  return (
    <section className={classes.container}>
      <img src={!isMobile ? BANNER_COOLEBRA_DESKTOP : BANNER_COOLEBRA_MOBILE} />
    </section>
  );
};

export default BannerCoolebra;
